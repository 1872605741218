import axios from 'axios';
import { toast } from 'react-toastify';

let url = `https://api-hmg.fideleco.com.br/api/v1/`;
const getToken = () => localStorage.getItem('@Fideleco:token');

const api = axios.create({
   baseURL: url,
   responseType: 'json',
   headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
   },
});

api.interceptors.request.use(async config => {
   const token = getToken();
   if (token) {
      config.headers.Authorization = `Bearer ${token}`;
   }
   return config;
});

api.interceptors.response.use(
   response => {
      return response;
   },
   async error => {
      const { response, config } = error;
      const { data } = response;

      return Promise.reject(error);
   },
);

export default api;
